<template>
  <div>
    <!-- <div class="wrapper"></div> -->
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
