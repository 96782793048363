export default {
  data() {
    return {
      isSmallScreen: false,
    };
  },
  computed: {
    inputSize() {
      return "sm";
    },

    xSmall() {
      return {
        "font-size": "x-small",
      };
    },

    buttonSizeClass() {
      return "btn-sm";
    },
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 991;
    },

    validatePhoneNumber(event) {
      const value = event.target.value.replace(/\D/g, "");
      this.form.phoneNumber = value;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
