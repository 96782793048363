import { PERMISSION } from "@/constants/permission.constant";

export const position = [
  {
    path: "/configurations/positions/create",
    name: "positions-create",
    component: () => import("@/views/configurations/positions/FormCreate.vue"),
    meta: { requiresAuth: true, permissions: [PERMISSION.POSITION_CREATE] },
  },

  {
    path: "/configurations/positions/edit/:id",
    name: "positions-edit",
    component: () => import("@/views/configurations/positions/FormEdit.vue"),
    meta: { requiresAuth: true, permissions: [PERMISSION.POSITION_UPDATE] },
  },
];
