import { PERMISSION } from "@/constants/permission.constant";

export const branchs = [
  {
    path: "/configurations/brands/create",
    name: "branchs-create",
    component: () => import("@/views/configurations/brands/FormCreate.vue"),
    meta: { requiresAuth: true, permissions: [PERMISSION.BRAND_CREATE] },
  },

  {
    path: "/configurations/brands/edit/:id",
    name: "branchs-edit",
    component: () => import("@/views/configurations/brands/FormEdit.vue"),
    meta: { requiresAuth: true, permissions: [PERMISSION.BRAND_UPDATE] },
  },
];
