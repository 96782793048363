const state = {
    userId: null,
};

const mutations = {
    setUserId(state, id) {
        state.userId = id;
    },
};

const actions = {
    updateUserId({ commit }, id) {
        commit('setUserId', id);
    },
};

const getters = {
    getUserId: (state) => state.userId,
};

export default {
    namespaced: true, // Enables namespacing
    state,
    mutations,
    actions,
    getters,
};
