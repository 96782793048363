export function toItemSelected({ text, value }) {
  const selectItem = { text: text, value: value };
  return selectItem;
}

export class MOptionFilter {
  constructor(data) {
    this.last = data?.last || false;
    this.pageNo = data?.pageNo || 0;
    this.pageSize = data?.pageSize || 25;
    this.totalElements = data?.totalElements || 0;
    this.totalPages = data?.totalPages || 0;
  }
}

export class MPaginitions extends MOptionFilter {
  constructor(data = {}) {
    super(data);
    this.content = data?.content;
  }
}
