<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link" style="opacity: 0">
      <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
        style="opacity: 0.8" />
      <span class="brand-text font-weight-light">AdminLTE 3</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav>
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
          <MenuItem v-for="(item, index) in menuItems" :title="item.title" :icon-class="item.icon" :icon="item.icon"
            :iconComponent="item.iconComponent" :children="item.children" :is-open="menuOpen"
            @handle-click-menu="toggleMenu" :is-collapsing="item.isCollapsing" :path="item.path"
            v-permission="item.permission" :key="item.path || index.toString()" />
        </ul>
      </nav>

      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import { PERMISSION } from "@/constants/permission.constant";
import { mapActions, mapGetters } from "vuex";
import MenuItem from "./MenuItem.vue";

export default {
  name: "AppSideBar",
  props: { menuItems: [] },
  components: { MenuItem },
  data() {
    return {
      menuOpen: "",
      permissionsList: [],
      PERMISSION,
      isCollapsing: null, // Holds the active menu path
      // Your menu structure
    };
  },
  computed: {
    ...mapGetters("menuStore", ["activeMenuItem"]),

  },
  methods: {
    ...mapActions("menuStore", ["setActiveMenuItem"]),
    toggleMenu(v) {
      // this.setMenuActived();

    },
    async fetchLatestData() {
      await this.$store.dispatch("userPermission/getPermissionsUsers");
    },

    setMenuActived() {


      const to = this.$route?.path;
      console.log({ to });

      if (!to) return;
      const pathNest = to?.split('/')?.filter(m => m);

      if (!pathNest?.length) return;

      pathNest?.forEach(el => {
        const children = document.querySelector(`ul.nav-sidebar a[href="/${el}"]`);
        if (children) {
          const parent = children.closest('li.nav-item');
          parent.classList.add('menu-is-opening', 'menu-open');

        }
      })
    },
    clearOpenAndActivedAll() {
      document.querySelectorAll('ul.nav-sidebar li.nav-item').forEach(el => {
        el.classList.remove('menu-is-opening', 'menu-open');
        const treeView = el.querySelector(`a.nav-link+ul.nav-treeview`);
        console.log({ treeView });

        if (!treeView)
          return;

        treeView.style.display = ''
      })
    }



  },
  watch: {



  },

  mounted() {
    // this.setActiveMenu(this.$route.path); // Initial route check(this.$route.name);
    this.setMenuActived()
  },
  created() {
    this.fetchLatestData().then((d) => { });
  },
};
</script>
<style lang="scss"></style>
