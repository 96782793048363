<template>
  <aside class="control-sidebar control-sidebar-dark">

  </aside>
</template>

<script>
  export default {
    name: 'ControlSideBar',
    data() {
      return {

      }
    }
  }
</script>

<style lang="scss">

</style>