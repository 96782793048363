import { DATE_FORAT } from "@/constants/ConstantType";
import moment from "moment";

Array.prototype.toDropdownlist = function () {
  return this.map((m) => ItemSelected({ text: m.name, value: m.id }));
};

export function ItemSelected({ text = null, value = null }) {
  const selectItem = { text: text, value: value };
  if (value) return selectItem;
  else {
    return null;
  }
}

export function isObjectNotEmpty(obj) {
  // the properties of the object
  let isContainTrue = [];
  for (let key in obj) {
    const value = obj[key];

    // If the property is an array, check if it's not empty
    if (value) {
      if (Array.isArray(value) && value) {
        if (value.length) {
          isContainTrue.push(true);
        }
      } else if (typeof value == "object" && value) {
        if (Object.keys(value)?.length) isContainTrue.push(true);
      } else {
        isContainTrue.push(true);
      }
    }
  }

  const isTrue = isContainTrue?.find((v) => v);
  return isTrue;
}

export function dateFormatLocal(date, format = "YYY/MM/DD HH:mm:ss [GMT]") {
  const dateForm = moment(date, format);
  const startDate = dateForm.format(DATE_FORAT.LOCAL_DATE); // January 1st, 00:00
  console.log({ startDate });

  return startDate;
}

export function getStartAndEndOfYear(year) {
  const format = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zzzz)";
  const dateNow = new Date();
  const dateYear = moment(dateNow, format).year(year);
  const startDate = dateYear.startOf("year").format(DATE_FORAT.LOCAL_DATE); // January 1st, 00:00
  const endDate = dateYear.endOf("year").format(DATE_FORAT.LOCAL_DATE); // December 31st, 23:59
  console.log(startDate, endDate);

  return {
    startDate,
    endDate,
  };
}

function getNestedValue(obj, path, delimiter = "/") {
  const keys = path.split(delimiter);

  return keys.reduce((acc, key) => {
    return acc ? acc[key] : undefined;
  }, obj);
}

function setNestedValue(obj, path, value, delimiter = "/") {
  const keys = path.split(delimiter);

  keys.reduce((acc, key, index) => {
    // If this is the last key, set the value
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      // Otherwise, keep iterating down the object, creating new objects if necessary
      if (!acc[key]) {
        acc[key] = {}; // create empty object if it doesn't exist
      }
    }
    return acc[key]; // move down the object
  }, obj);
}
