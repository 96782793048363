import { PERMISSION } from "@/constants/permission.constant";

export const sources = [
  {
    path: "/configurations/sources/create",
    name: "sources-create",
    component: () => import("@/views/configurations/sources/FormCreate.vue"),
    meta: { requiresAuth: true, permissions: [PERMISSION.SOURCES_CREATE] },
  },

  {
    path: "/configurations/sources/edit/:id",
    name: "sources-edit",
    component: () => import("@/views/configurations/sources/FormEdit.vue"),
    meta: { requiresAuth: true, permissions: [PERMISSION.SOURCES_UPDATE] },
  },
];
