import Swal from "sweetalert2";

/**
 * Returns an object containing global toast functions.
 * @returns {Object} - An object with success, info, warning, and error functions.
 */
export function getGlobalToast() {
  return {
    success: (message) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: message,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    info: (message) => {
      Swal.fire({
        icon: "info",
        title: "Info",
        text: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    warning: (message) => {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: message,
      });
    },
    error: (error) => {
      if (error.response) {
        const { status, data } = error.response;
        handleHttpResponseError(status, data.message || "An error occurred");
      } else if (error.request) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No response received from the server",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error || "",
          width: "400px",
        });
      }
    },
  };
}
