import { HelperService } from "@/share/helper.service";

const ACTON_NAME = {
  SET_CITY: "SET_CITY",
  SET_ADVISOR: "SET_ADVISOR",
};
const state = {
  userId: null,
  cities: [],
  advisores: [],
};

const mutations = {
  [ACTON_NAME.SET_CITY](state, data) {
    state.cities = data;
  },

  [ACTON_NAME.SET_ADVISOR](state, data) {
    state.advisores = data;
  },

  setUserId(state, id) {
    state.userId = id;
  },
};

const actions = {
  updateUserId({ commit }, id) {
    commit("setUserId", id);
  },
  async cityAction({ commit }) {
    const listCity = await HelperService.getCity();
    commit(ACTON_NAME.SET_CITY, listCity);
  },
  async advisorAction({ commit }) {
    const listAdvisor = await HelperService.getAdvisor();
    commit(ACTON_NAME.SET_ADVISOR, listAdvisor);
  },
};

const getters = {
  getUserId: (state) => state.userId,
  getCities: (state) => state.cities,
  getAdvisor: (state) => state.advisores,
};

export default {
  namespaced: true, // Enables namespacing
  state,
  mutations,
  actions,
  getters,
};
