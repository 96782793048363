<template>
  <b-form-group :label="label" :disabled="disabled" :label-for="labelFor" :class="customClass"
    class="text-left custom-form-group-input">
    <slot></slot>
    <b-form-invalid-feedback v-if="invalidFeedback" :id="feedbackId">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "BaseFormGroup",
  props: {
    disabled: { type: Boolean, default: false },
    label: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    invalidFeedback: {
      type: String,
      default: "",
    },
    feedbackId: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
/* Add any custom styling here if needed */
</style>
