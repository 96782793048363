<template>
  <div class="modal-delete">
    <b-modal
      ref="baseModal"
      class="modal-sm"
      id="delete-modal-comfirm"
      size="sm"
      centered
      :hide-header="false"
      :hide-footer="true"
    >
      <template #modal-title>
        <div class="d-flex align-items-center">
          <slot name="title">{{ title }} </slot>
        </div>
      </template>

      <div class="d-block text-center p-3">
        <slot name="content"> </slot>
      </div>
      <hr style="opacity: 0.12" class="py-0 my-0" />
      <div class="modal-footer" style="border-top: none">
        <slot name="footer">
          <!-- Default footer buttons (optional) -->

          <b-button v-if="showCloseButton" :class="'btn-sm'" @click="hideModal">
            {{ closeButtonText }}
          </b-button>

          <b-button
            v-if="showToggleButton"
            :variant="toggleButtonVariant"
            :class="'btn-sm  mr-0'"
            @click="toggleModal"
          >
            {{ toggleButtonText }}
          </b-button>
        </slot>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    title: {
      type: String,
      default: "Delete",
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeButtonText: {
      type: String,
      default: "No",
    },
    closeButtonVariant: {
      type: String,
      default: "light",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
    toggleButtonText: {
      type: String,
      default: "Yes",
    },
    toggleButtonVariant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      rowData: {},
    };
  },
  methods: {
    showModal(data) {
      this.rowData = data;
      this.$refs["baseModal"].show();
    },
    hideModal() {
      this.$emit("click-cancel", this.rowData);
      this.$refs["baseModal"].hide();
    },
    toggleModal() {
      this.$emit("click-ok", this.rowData);
      this.$refs["baseModal"].toggle();
    },
  },
  mounted() {},
};
</script>
<style></style>
<style scoped>
.modal-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modal-footer {
  padding: 1rem 1rem 1rem 0rem !important;
}

/* Add any additional styling here */
</style>
