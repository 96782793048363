<template>
  <nav
    class="main-header navbar navbar-expand navbar-white navbar-light ml-0"
    style="
      position: sticky;
      top: 0;
      z-index: 10001;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    "
  >
    <ul class="navbar-nav pl-3 align-items-center">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fa fa-bars" style="font-size: 1.55rem"></i
        ></a>
      </li>

      <li class="nav-item d-flex justify-content-center align-items-center">
        <a href="../../index3.html" class="brand-link">
          <img
            src="@/assets/logo.png"
            alt="AdminLTE Logo"
            class="brand-image"
          />
          <!-- <span class="brand-text font-weight-light">Admin</span> -->
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" style="gap: 1.5rem; padding-right: 0.5rem">
      <li
        class="nav-item dropdown d-flex justify-content-center align-items-center"
      >
        <a
          class="nav-link cursor-pointer"
          data-toggle="dropdown"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.6rem;
          "
        >
          <img :src="bellIcon" style="width: 15px" alt="Notifications" />
          <span
            class="badge badge-warning navbar-badge"
            style="right: -7px; top: 0px; background-color: #f84518"
            >5</span
          >
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
        </div>
      </li>

      <li class="nav-item dropdown cursor-pointer" style="margin-right: 15px">
        <a
          class="nav-link px-0"
          data-toggle="dropdown"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.85rem;
          "
        >
          <img
            src="@/assets/user-female.jpg"
            v-if="this.gender === 'Female'"
            class="img-circle elevation-2"
            alt="User Image"
            style="width: 32px; vertical-align: middle"
          />

          <img
            src="@/assets/profile.png"
            v-else
            class="img-circle elevation-2"
            alt="User Image"
            style="width: 32px; vertical-align: middle"
          />

          {{ this.fullName }}
          <img :src="arrowButtom" style="width: 10px" alt="Notifications" />
        </a>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
          style="margin-top: 0.65rem"
        >
          <a
            href="/user-profile"
            class="dropdown-item"
            style="display: flex; align-items: center"
          >
            <img
              src="@/assets/user-female.jpg"
              class="img-circle"
              alt="User Image"
              v-if="this.gender === 'Female'"
              style="width: 35px; vertical-align: middle"
            />
            <img
              src="@/assets/profile1.png"
              class="img-circle"
              alt="User Image"
              v-else
              style="width: 35px; vertical-align: middle"
            /><span style="font-size: 12px; padding: 5px">
              <label class="mb-0">{{ this.fullName }}</label
              ><br />{{ this.phoneNumber }}
            </span>
          </a>
          <div class="dropdown-divider"></div>
          <a
            href="/user-profile"
            class="dropdown-item"
            style="display: flex; align-items: center"
          >
            <img
              :src="UserImg"
              style="width: 20px"
              alt="Profile"
            />&nbsp;Profile
          </a>
          <div class="dropdown-divider"></div>
          <a
            href="#"
            @click.prevent="handleClickLogout"
            class="dropdown-item dropdown-footer"
            style="align-items: center"
          >
            <img
              :src="LogoutImg"
              style="width: 20px; color: #e82126"
              alt="Profile"
            />&nbsp;Logout
          </a>
        </div>
      </li>
    </ul>
    <BaseModal
      ref="form-model-confirm-logout"
      :hideFooter="false"
      :showCloseButton="true"
      @click-ok="handleOkConfirm"
      toggleButtonText="Logout"
      toggleButtonVariant="danger"
    >
      <template #title>
        <label class="text text-danger">Logout?</label>
      </template>
      <template #content>
        <div style="width: fit-content">Are you sure want to logout?</div>
      </template>
    </BaseModal>
  </nav>
</template>

<script>
import arrowButtom from "@/assets/icons/arrow-buttom.png";
import bellIcon from "@/assets/icons/bell-icon.png";
import LogoutImg from "@/assets/icons/Logout.png";
import UserImg from "@/assets/icons/User.png";
import BaseModal from "@/components/FormControls/ModalConfirm.vue";
import { handleError } from "@/errorHandler";
import { CredentialService } from "@/services/CredentialService";
import UserService from "@/services/UserService.js";
export default {
  name: "AppNavBar",
  components: { BaseModal },
  data() {
    return {
      userId: null,
      fullName: "",
      phoneNumber: "",
      username: "",
      gender: "",
      selectedUser: {},
      bellIcon,
      arrowButtom,
      LogoutImg,
      UserImg,
    };
  },
  mounted() {
    const credentials = CredentialService.getCredential();
    const data = {
      username: credentials.username,
    };
    this.fetchUser(data);
  },

  methods: {
    handleClickLogout() {
      this.$refs["form-model-confirm-logout"].showModal();
    },
    handleOkConfirm() {
      this.handleLogout();
    },
    handleLogout() {
      this.$store.dispatch("userPermission/resetUserPermissionsState");
      localStorage.clear(); // Clear localStorage to remove persisted Vuex state
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    async fetchUser(username) {
      try {
        this.selectedUser = await UserService.getProfileById(username);
        this.fullName = this.selectedUser.name;
        this.phoneNumber = this.selectedUser.phoneNumber;
        this.gender = this.selectedUser.gender;
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>

<style lang="scss">
.user-fullname {
  align-self: center;
  color: white;
}
</style>
