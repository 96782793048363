import api from "@/services/AxiosConfig";
import { ConfigurationService } from "@/services/ConfigurationSetup";
import { MPaginitions } from "@/share/helper.ulity";
import moment from "moment";

export class ModelLeads {
  static get dropdown() {
    const configuration = new ConfigurationService();
    return configuration.findAllDropdownLocal() || {};
  }

  constructor(data) {
    this.id = data?.id;
    this.sourceId = data?.sourceId;
    this.customerName = data?.customerName;
    this.phoneNumber = data?.phoneNumber;
    this.provinceId = data?.provinceId;
    this.plateNumber = data?.plateNumber;
    this.brandId = data?.brandId;
    this.modelId = data?.modelId;
    this.year = data?.year;
    this.colorId = data?.colorId;
    this.serviceTypeIds = data?.serviceTypeIds;
    this.serviceAdvisorIds = data?.serviceAdvisorIds;
    this.leadsComplains = data?.leadsComplains;
    this.remark = data?.remark;
    this.statusCode = data?.statusCode;
    this.isActive = data?.isActive;
    this.active = data.active;
    this.createdAt = data.createdAt;
    this.cityId = data.provinceId;
    this.source = data?.source;
    this.followUpDate = data.followUpDate;
    this.bookedDate = data.bookedDate;
    this.bookedDateLocal = data.bookedDate
      ? moment(data.bookedDate).format("DD/MM/YY -hh:mm A")
      : "";
    this.followUpDateLocal = data.followUpDate
      ? moment(data.followUpDate).format("DD/MM/YY -hh:mm A")
      : "";
    this.createdAtLocal = moment(data.createdAt).format("DD/MM/YY");
    this.serviceTypes = data.serviceTypes?.length
      ? ModelLeads.dropdown.SERVICE_TYPE?.filter((dt) =>
        data?.serviceTypes?.find((a) => a.id == dt.id)
      ).map(({ code, name: text, id: value }) => ({ code, value, text }))
      : [];

    this.brand = {};
    this.source = {};
    this.model = {};
    this.color = {};
    this.city = {};
    this.serviceType = [];
    this.serviceAdvisors = [];

    this.serviceType = data?.serviceTypes;
    this.serviceAdvisors = data?.serviceAdvisors;

    const _color = ModelLeads.dropdown?.COLOR?.find(
      (br) => br?.id == data?.colorId
    );
    this.color = _color;

    const _city = ModelLeads.dropdown?.CITY?.find(
      (c) => c?.id == data?.provinceId
    );

    this.city = _city;

    const vbrand = ModelLeads.dropdown?.BRAND?.find(
      (br) => br?.id == data?.brandId
    );
    this.brand = vbrand;

    const vource = ModelLeads.dropdown?.SOURCE?.find(
      (src) => src?.id == data?.sourceId
    );
    this.source = vource;

    const vmodel = ModelLeads.dropdown?.MODEL?.find(
      (mod) => mod?.id == data?.modelId
    );
    this.model = vmodel;
  }

  formModel() { }
  // You can add methods to this class if needed
  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }
}
const _URL_API = "leads";
const _DROPDOWNLIST = "dropdown/get-leads-options";
const REMARK = "remark";
export class LeadsService {
  static save(body) {
    const parmsBody = { ...body };
    return api
      .post(`${_URL_API}`, parmsBody)
      .then((res) => ({ success: res.data }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }

  static update({ id, body }) {
    const parmsBody = body;
    return api
      .put(`${_URL_API}/${id}`, parmsBody)
      .then((res) => ({ success: res.data }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }
  static getListLeads({ params }) {
    const findLeads = api
      .get(_URL_API, { params })
      .then((res) => ({
        success: new MPaginitions({
          ...res.data,
          pageNo: res.data.pageNo + 1,
          content: res.data.content.map((m) => ({ ...new ModelLeads(m) })),
        }),
      }))
      .catch((er) => ({ error: er }));
    return findLeads;
  }

  static getDropdownLeads() {
    const findAllDropdown = api
      .get(_DROPDOWNLIST)
      .then((d) => ({ success: d.data }))
      .catch((er) => ({ error: er }));
    return findAllDropdown;
  }

  static getLeadsById({ id }) {
    return api
      .get(`${_URL_API}/${id}`)
      .then((res) => ({ success: new ModelLeads(res.data) }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }

  static async exportLeads({ body, params }) {
    const parmsBody = { ...body };
    const queryParam = Object.keys(params)
      ? Object.keys(params)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
        )
        .join("&")
      : "";

    try {
      const response = await api.post(
        `${_URL_API}/export`,
        parmsBody,
        { params: params },
        {
          responseType: "blob", // Important: Response type set to 'blob' for file download
        }
      );

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a link element to download the file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "Leads.csv"); // Specify the filename

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically trigger the click event
      link.click();

      // Remove the link after download
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  }
  static async getRemark(leadsId) {
    return api
      .get(`${_URL_API}/${REMARK}/${leadsId}`)
      .then((d) => ({ success: d.data }))
      .catch((er) => ({ error: er }));
  }
}

export function MappFormData({ data, citiesOption, STATUS_LIST }) {
  const {
    brand,
    source,
    serviceTypes,
    serviceType,
    serviceAdvisors,
    followUpDate,
    bookedDate,
    model,
    color,
    statusCode,
    ...propsData
  } = {
    ...new ModelLeads(data),
  };

  const cities = citiesOption.find((m) => m.value == data.cityId);

  const formModel = {
    ...(propsData || {}),
    brand: brand?.id ? { text: brand?.name, value: brand?.id } : null,
    status: STATUS_LIST?.find((m) => m?.value == statusCode),
    source: source?.id ? { text: source?.name, value: source?.id } : null,
    model: model?.id ? { text: model?.name, value: model?.id } : null,
    city: cities?.value ? cities : null,
    color: color?.id ? { text: color?.name, value: color?.id } : null,
    serviceAdvisor: serviceAdvisors?.length
      ? serviceAdvisors?.map((m) => ({ text: m.fullName, value: m.id }))
      : null,

    serviceType:
      serviceType && Array.isArray(serviceType)
        ? serviceType.find((m) => m)
        : serviceType,

    followUpDate: (followUpDate ? moment(followUpDate).utc() : moment())
      .local()
      .format("YYYY-MM-DD HH:mm"),

    bookedDate: (bookedDate ? moment(bookedDate).utc() : moment())
      .local()
      .format("YYYY-MM-DD HH:mm"),
  };

  return formModel;
}
