<template>
  <footer class="main-footer">
    <div class="float-right d-none d-sm-block">
      <b>Version</b> 3.2.0
    </div>
    <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights reserved.
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">

</style>